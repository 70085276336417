<template>
  <Loader :value="false" theme="overlay" class="flatfinder-filter">
    <Modal
      v-if="isMobile"
      :show="showModal"
      @close="closeModal"
      :clickaway="false"
      class="flatfinder-filter__modal"
    >
      <h2>{{ $t('filter') }}</h2>
      <Items
        :is-mobile="isMobile"
        :filters="filters"
        :data="data"
        @update:filter="item => updateData(item)"
        @remove:filter="filter => removeFilter(filter)"
      />

      <div class="flatfinder-filter__modal-bottom">
        <ButtonComponent icon-placement="left" :icon="['fal', 'times']" @click="resetFilter" />
        <ButtonComponent theme="primary" :label="$t('filter')" @click="closeModal" />
      </div>
    </Modal>

    <Items
      v-else
      :filters="filters"
      :data="data"
      @update:filter="item => updateData(item)"
      @remove:filter="filter => removeFilter(filter)"
    />

    <component
      :is="isMobile ? 'div' : 'DropdownComponent'"
      v-if="isMobile ? filters.length || availableFilters.length : availableFilters.length"
      :close-on-click="true"
      class="flatfinder-filter__trigger"
    >
      <div class="flatfinder-filter__trigger-add" @click="isMobile ? prepareAllValues() : () => {}">
        <div class="flatfinder-filter__trigger-add-icon">
          <FontAwesomeIcon :icon="['fal', 'plus']" size="lg" />
        </div>
        <label class="flatfinder-filter__trigger-add-label">
          <template>
            <span>{{ $t('clickToFilter') }}</span>
            <span>{{ $t('select') }}</span>
          </template>
        </label>
      </div>
      <template #dropdown>
        <ButtonComponent
          :label="filter.label"
          :icon="filter.icon"
          v-for="filter in availableFilters"
          :key="'add_' + filter.key"
          class="flatfinder-filter__trigger-add-button"
          @click="prepareValue(filter.key, true)"
        />
      </template>
    </component>
  </Loader>
</template>

<script>
import Vue from 'vue'
import { DropdownComponent } from 'vue-elder-dropdown'
import { getFlatfinderMetadata } from '../api'
import { isNumeric } from '../utils'
import Types from './Types'
import Items from './Items'
import { ModalComponent as Modal } from 'vue-elder-modal'

const EnumFinder = key => e => e.key === key && (!e.models || e.models.includes('Residential'))

const TypeMap = {
  SquareMetre: 'RangeSlider',
  Enum: 'Select',
  Currency: 'RangeSlider',
  Int: 'RangeSlider',
  Boolean: 'Boolean',
}

const IconMap = {
  SquareMetre: ['fal', 'ruler-triangle'],
  Enum: ['fal', 'check-square'],
  Currency: ['fal', 'tag'],
  Int: ['fal', 'sort-numeric-up'],
  Boolean: ['fal', 'toggle-on'],
}

const additionalProperties = [
  {
    key: 'price',
    unit: 'Currency',
  },
]

const getRangeSliderValues = properties => {
  if (!properties || properties.length === 0 || !Array.isArray(properties)) return {}

  const first = properties[0]
  const last = properties.at(-1)

  const result = last - first

  let factor = 0

  if (result < 500) {
    factor = 0
  } else if (result < 10000) {
    factor = 1
  } else if (result < 1000000) {
    factor = 2
  } else {
    factor = 4
  }

  const step = Math.pow(10, factor)

  const min = first % step === 0 ? first : Math.floor(first / step) * step
  const max = last % step === 0 ? last : Math.ceil(last / step) * step

  return {
    step,
    min,
    max,
  }
}

const parseValue = value => {
  // Range
  if ([/^-\d+$/, /^\d+-$/, /^\d+-\d+$/].some(p => p.test(value))) {
    let [from, to] = value.split('-')
    from = parseFloat(from)
    to = parseFloat(to) || from
    return [from, to]
  }
  // Number
  if (isNumeric(value)) return value
  // Select
  if (value.includes(',')) return value.split(',')
  // Boolean
  if ([true, false, 'true', 'false'].includes(value))
    return ['true', true].includes(value) ? true : false
  // Default
  return value
}

export default {
  inject: ['current'],
  watch: {
    data: {
      handler(val) {
        if (!this.initialized) return
        this.$emit('filter', this.dataToValue(val))
      },
      deep: true,
    },
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
      defaultFilters: [],
      propertiesEnum: [],
      properties: {},
      promise: null,
      initialized: false,
      showModal: false,
    }
  },
  computed: {
    filters() {
      return Object.keys(this.data)
        .map(filter => {
          let match = this.propertiesEnum.find(EnumFinder(filter))
          if (!match) return
          if (!this.properties[filter] || this.properties[filter].length < 2) return
          const properties = this.properties[match.key]

          let type = TypeMap[match.unit]

          if ((type === 'RangeSlider' && properties.length < 5) || !match.unit) type = 'Select'

          let getData = match => {
            let listeners = {
              disable: () => this.removeFilter({ key: match.key }),
            }
            let bindings = {
              label: this.$tc(match.key),
              icon: IconMap[match.key] || IconMap[match.unit] || IconMap['Enum'],
              enum: match,
            }

            this.$set(this.data[match.key], 'type', type)

            switch (match.unit) {
              case 'SquareMetre':
                bindings.suffix = 'm²'
                break
              case 'Currency':
                bindings.type = 'currency'
            }

            switch (type) {
              case 'Range':
                if (!this.$path(`data.${match.key}.value`, this))
                  this.$set(this.data[match.key], 'value', [
                    properties[0],
                    properties[properties.length - 1],
                  ])
                break
              case 'RangeSlider':
                bindings = {
                  ...bindings,
                  ...getRangeSliderValues(properties),
                }

                if (!this.$path(`data.${match.key}.value`, this)) {
                  this.$set(this.data[match.key], 'value', [bindings.min, bindings.max])
                }

                break
              case 'Select':
                if (match.values)
                  bindings.items = match.values
                    .filter(v => properties.includes(v))
                    .map(v => ({ value: v, label: this.$tc(`${match.key}:${v}`, 1) }))
                else
                  bindings.items = properties.map(v => {
                    return {
                      value: v.toString(),
                      label: [
                        bindings.prefix,
                        isNumeric(v) ? v.toLocaleString('nb-NO') : v.toString(),
                        bindings.suffix,
                      ]
                        .filter(Boolean)
                        .join(' '),
                    }
                  })
                if (!this.$path(`data.${match.key}.value`, this))
                  this.$set(this.data[match.key], 'value', [...bindings.items.map(v => v.value)])
                break
              case 'Boolean':
                const value =
                  this.$path(`data.${match.key}.value`, this) !== null
                    ? this.data[match.key].value.toString()
                    : null
                if (!value) {
                  this.$set(this.data[match.key], 'value', true)
                }
            }

            switch (match.key) {
              case 'constructionYear':
                bindings.mask = { mask: Number, unmask: true }
                break
            }

            return {
              listeners,
              bindings,
            }
          }
          return {
            component: Types[type],
            key: match.key,
            ...getData(match),
          }
        })
        .filter(Boolean)
    },
    availableFilters() {
      return Object.entries(this.properties)
        .filter(([key, value]) => value.length > 1 && !(key in this.data))
        .map(([key]) => {
          let match = this.propertiesEnum.find(EnumFinder(key))
          if (!match) return
          return {
            key,
            icon: IconMap[match.key] || IconMap[match.unit] || IconMap['Enum'],
            label: this.$t(match.key),
          }
        })
        .filter(Boolean)
    },
  },
  methods: {
    updateData(item) {
      let { key, value } = item
      this.data[key].value = value
    },
    init() {
      this.fetchMetadata().then(() => {
        if (this.$route.query['flatfinder-filter']) {
          let qFilters =
            this.$route.query['flatfinder-filter'] instanceof Array
              ? this.$route.query['flatfinder-filter']
              : [this.$route.query['flatfinder-filter']]
          this.data = this.valueToData(qFilters)
        }
        this.defaultFilters.forEach(f => {
          if (f in this.data) return
          this.$set(this.data, f, { enabled: false, type: null, value: null })
        })
        this.initialized = true
      })
    },
    closeModal() {
      this.showModal = false
    },
    prepareAllValues() {
      this.showModal = true
      if (!this.availableFilters.length) return
      return this.availableFilters.forEach(i => this.prepareValue(i.key, false))
    },
    prepareValue(filter, enabled = false) {
      if (filter && !(filter in this.data))
        this.$set(this.data, filter, { enabled, type: null, value: null })
    },
    removeFilter(filter) {
      if (this.isMobile) return (this.data[filter.key].enabled = false)
      if (this.defaultFilters.includes(filter.key)) return (this.data[filter.key].enabled = false)
      else Vue.delete(this.data, filter.key)
    },
    resetFilter() {
      this.showModal = false
      if (!this.filters.length) return
      return this.filters.forEach(i => Vue.delete(this.data, i.key))
    },
    fetchMetadata() {
      this.promise = getFlatfinderMetadata({
        id: this.current.id,
        properties: Object.keys(this.data),
      }).then(data => {
        this.propertiesEnum = [...data.Enums.ResidentialProperties, ...additionalProperties]
        this.properties = data.Flatfinder.project.metadata.residentialProperties

        this.defaultFilters = data.Flatfinder.project.metadata.siteSettings.defaultFlatfinderFilters
      })

      return this.promise
    },
    valueToData(val) {
      return Object.fromEntries(
        val
          .map(v => {
            let [key, value] = v.split(':')
            let match = this.propertiesEnum.find(EnumFinder(key))
            if (!match) return

            return [key, { enabled: true, value: parseValue(value) }]
          })
          .filter(Boolean),
      )
    },
    dataToValue(data) {
      return Object.entries(data)
        .filter(([, v]) => v.enabled)
        .map(([key, { value, type }]) => {
          let match = this.propertiesEnum.find(EnumFinder(key))
          if (!match || !value) return
          const sorted = [...value].map(a => (isNaN(a) ? a : Number(a))).sort((a, b) => a - b)
          switch (type) {
            case 'RangeSlider':
              value = sorted.join('-')
              break
            case 'Range':
              value = sorted.join('-')
              break
            case 'Select':
              value = sorted.join(',')
              break
          }

          return `${key}:${value}`
        })
        .filter(Boolean)
        .sort()
    },
  },
  created() {
    this.init()
  },
  components: {
    DropdownComponent,
    Modal,
    Items,
  },
}
</script>

<style lang="scss">
.flatfinder-filter {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  max-width: 1150px;
  margin: 2rem auto;

  @include respond-below('phone') {
    margin-top: 0;
  }

  .flatfinder-filter-items__item,
  &__trigger {
    max-width: 255px;
    width: 100%;
    border-radius: var(--border-radius);
    padding: 1rem 1.25rem;
    position: relative;
    transition: background-color 0.2s ease-out;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: currentColor;
      opacity: 0.02;
      border-radius: inherit;
      pointer-events: none;
      z-index: -1;
    }
  }

  &__trigger {
    &-add {
      cursor: pointer;

      display: flex;
      padding: 0 1rem;
      align-items: center;
      gap: 0.5rem;

      @include respond-below('phone') {
        padding: 0 0.2rem;
      }

      &-label {
        padding-right: 1.5em;
        cursor: inherit;

        span {
          display: block;

          &:first-child {
            opacity: 0.8;
            font-weight: normal;
            white-space: nowrap;
            font-size: 0.7em;
          }
        }
      }

      &-icon {
        position: relative;
        width: 45px;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: space-between;

        @include respond-below('phone') {
          width: 20px;
        }
      }

      &-button {
        border: none !important;
      }
    }
  }

  .elder-dropdown__content {
    font-size: 1em;
    z-index: 1001;
  }

  &__modal {
    display: initial;
    background-color: white;

    .flatfinder-filter-items {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__item {
        padding: 0.5rem;
        max-width: initial;
        width: 100%;
      }
    }

    &-bottom {
      margin-top: 1.5rem;

      display: flex;
      justify-content: space-between;

      .elder-button {
        font-size: 1rem !important;
      }
    }
  }
}
</style>
